import '../../utils/marketingPageDataFragment';

import { TealSplats2 } from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import CommunityModule, { CommunityDataPropsObject } from '../../components/CommunityModule';
import CopyBlockModule, { CopyBlockDataPropsObject } from '../../components/CopyBlockModule';
import CopyLockupListModule, { CopyLockupListDataPropsObject } from '../../components/CopyLockupListModule';
import HeroModule, { HeroDataPropsObject } from '../../components/HeroModule';
import PitchModule, { PitchDataPropsObject } from '../../components/PitchModule';
import ProTipModule, { ProTipDataPropsObject } from '../../components/ProTipModule';
import getBlobs from '../../components/ProTipModule/getBlobs';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import getSeoInfo from '../../utils/getSeoInfoForMarketingPage';
import {
  community as communityClass,
  copyBlock as copyBlockClass,
  copyLockupList as copyLockupListClass,
  pitch as pitchClass,
  tealSplats2,
  textHero
} from './MarketingFeaturesIndividualPage.module.css';

export default function MarketingFeaturesIndividualPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;

  const {
    community,
    copyBlock,
    copyLockupList,
    pitch,
    proTip,
    hero
  } = componentsQueryResultToData(components);

  const SEOInfo = getSeoInfo(page, description, canonicalUrlPage, imageFieldName, hero);

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <HeroModule className={textHero} data={hero} />
      <CopyBlockModule className={copyBlockClass} data={copyBlock} />
      {proTip.map((proTipItem, index) => <ProTipModule key={proTipItem.id} assetBlobs={index === 3} blobs={getBlobs(index)} data={proTipItem} tipBlobsVersion={(index + 1)} />)}
      <CopyLockupListModule childrenLayout="fullWidthCopy" className={copyLockupListClass} data={copyLockupList} />
      <PitchModule
        blobs={(
          <LazyLoad once>
            <TealSplats2 className={tealSplats2} />
          </LazyLoad>
        )}
        className={pitchClass}
        data={pitch}
      />
      <CommunityModule className={communityClass} data={community} />
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingFeaturesIndividualPageByUrl($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingFeaturesIndividualPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCommunity']),
          ...CommunityDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyBlock']),
          ...CopyBlockDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentCopyLockupList']),
          ...CopyLockupListDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentHero']),
          ...HeroDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentProTip']),
          ...ProTipDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentPitch']),
          ...PitchDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
