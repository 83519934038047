// extracted by mini-css-extract-plugin
export var base = "CopyBlockModule-module--base--1w-ga";
export var rightAlignedContainer = "CopyBlockModule-module--rightAlignedContainer--2-e3e";
export var rightAlignedContainerSmall = "CopyBlockModule-module--rightAlignedContainerSmall--3o_-X";
export var leftAlignedContainer = "CopyBlockModule-module--leftAlignedContainer--tw9in";
export var blogContainer = "CopyBlockModule-module--blogContainer--3pcjF CopyBlockModule-module--base--1w-ga";
export var container = "CopyBlockModule-module--container--3TweE CopyBlockModule-module--base--1w-ga";
export var largeContainer = "CopyBlockModule-module--largeContainer--3x7jE CopyBlockModule-module--base--1w-ga";
export var mobileSmallPadding = "CopyBlockModule-module--mobileSmallPadding--2D9Eg";
export var fullWidthMobile = "CopyBlockModule-module--fullWidthMobile--2uVdK";
export var gridWithSidebar = "CopyBlockModule-module--gridWithSidebar--1pmn8";
export var noMaxWidth = "CopyBlockModule-module--noMaxWidth--1tFnY";
export var wrap = "CopyBlockModule-module--wrap--2lV1t";
export var copyBlock = "CopyBlockModule-module--copyBlock--3DdPa CopyBlockModule-module--container--3TweE CopyBlockModule-module--base--1w-ga CopyBlockModule-module--mobileSmallPadding--2D9Eg";
export var marginContainer = "CopyBlockModule-module--marginContainer--wO4Mj";