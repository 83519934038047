import { CopyBlock } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ModuleMarkdown from '../ModuleMarkdown';
import { copyBlock, marginContainer, wrap } from './CopyBlockModule.module.css';

export default function CopyBlockModule({
  blobs,
  className,
  data
}) {
  const {
    collapsibleContent,
    copy,
    hasMarginBottom,
    readMoreTrackingClass,
    title
  } = data;

  const copyBlockClasses = classNames(className, copyBlock);
  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      {blobs}
      <CopyBlock
        body={<ModuleMarkdown>{copy.childMarkdownRemark.htmlAst}</ModuleMarkdown>}
        className={copyBlockClasses}
        collapsibleContent={collapsibleContent && <ModuleMarkdown>{collapsibleContent.childMarkdownRemark.htmlAst}</ModuleMarkdown>}
        readMoreTrackingClass={readMoreTrackingClass}
        title={title}
      />
    </div>
  );
}

export const CopyBlockDataPropsObject = {
  collapsibleContent : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }),
  copy : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }).isRequired,
  hasMarginBottom       : PropTypes.bool,
  readMoreTrackingClass : PropTypes.string,
  title                 : PropTypes.string.isRequired
};

CopyBlockModule.propTypes = {
  blobs     : PropTypes.node,
  className : PropTypes.string,
  data      : PropTypes.shape(CopyBlockDataPropsObject).isRequired
};

CopyBlockModule.defaultProps = {
  blobs     : null,
  className : null
};

export const CopyBlockModuleData = graphql`
  fragment CopyBlockModuleData on ContentfulComponentCopyBlock {
    collapsibleContent {
      childMarkdownRemark {
        htmlAst
      }
    }
    copy {
      childMarkdownRemark {
        htmlAst
      }
    }
    hasMarginBottom
    readMoreTrackingClass
    title
  }
`;
